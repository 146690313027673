<script>
import { Line, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
  name: "LineChart",
  mixins: [Line, reactiveProp],
  props: ["scales", "tooltips"],
  data: function () {
    return {};
  },
  mounted() {
    this.renderChart(this.chartData, {
      legend: {
        display: false,
        labels: {
          fontFamily: "Nunito Sans",
        },
      },
      scales: this.scales,
      tooltips: this.tooltips,
    });
  },
};
</script>

<style scoped>
</style>